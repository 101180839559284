<template>
  <div class="mx-10 mt-8" >
    <v-container>

      <div class="text-h4 font-weight-medium primary--text mb-6">Set a New Password</div>
      <v-form ref="form" class=" d-flex flex-column " v-model="valid" lazy-validation>
        <v-text-field :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min] "
        outlined dense 
        validate-on-blur :type="showOldPassword ? 'text' : 'password'" v-model="oldPassword" label="Old Password"
        class="input-group--focused " @click:append="showOldPassword = !showOldPassword" required></v-text-field>
        <v-text-field :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
        outlined dense 
        validate-on-blur :type="showPassword1 ? 'text' : 'password'" v-model="password" label="New Password"
        class="input-group--focused" @click:append="showPassword1 = !showPassword1" required></v-text-field>
        <v-text-field :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
        outlined dense 
        :rules="[rules.required, rules.min, (this.password === this.password2) || 'Password must match']"
        validate-on-blur :type="showPassword2 ? 'text' : 'password'" v-model="password2" label="Confirm New Password"
        class="input-group--focused" @click:append="showPassword2 = !showPassword2" required></v-text-field>
        <v-btn elevation="0" class="mx-auto mt-10 mb-4" @click="changePass()" color="primary" value="log in">Change
          Password</v-btn>
          
        </v-form>
      </v-container>
  </div>
</template>
<script>
import { changePassword } from '@/api/users'
export default {
  name: 'ChangePassword',
  data() {
    return {
      valid: true,
      userId: this.$store.state.UserId,
      showPassword1: false,
      showPassword2: false,
      showOldPassword: false,
      oldPassword: '',
      password: '',
      password2: '',
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        min: v => v.length >= 8 || 'Min 8 characters',
        // passwordMatch: () => selectedUser.password != selectedUser.password2 || 'Passwords doesn\'t match',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      }

    }
  },
  methods: {
    changePass() {
      if(this.$refs.form.validate()){
        const _this = this
  
  
        changePassword(this.userId, this.oldPassword, this.password, this.password2, function () {
          _this.oldPassword = ''
          _this.password = ''
          _this.password2 = ''
        }, function (err) {
          if (err.status == 400 && err?.data?.Message) {
            var msg = ''
            for (const k of Object.keys( err?.data?.Message)) {
              msg += err?.data?.Message[k][0]
            }
            _this.$root.notify.show({ message: msg, type: 'error' })
          }
          else {
            _this.$root.notify.show({ message: 'Internal Error!', type: 'error' })
          }
        }, 'Password changed successfully')
      }
      
    }
  },
  created() {
  }
}
</script>
